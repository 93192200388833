<template>
    <Overlay v-if="dataObject.state.isLoading || valuesDataObject?.state.isLoading"/>
    <table v-if="dataObject">
        <tr v-for="(item,index) in list.items"> 
            <td>
                <div class="fw-bold" v-if="item.groupByHeader !== list.items[index-1]?.groupByHeader">{{item.groupByHeader}}</div>
               {{item.caption}}
               <button v-if="item.title" type="button" class="btn btn-link btn-sm" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right" data-bs-trigger="focus" :data-bs-content="item.title" v-popover>
                    <i class="bi bi-question-circle"></i>
                </button>
            </td>
            <td>
                <EditorItem :item="item"/>
            </td>
        </tr>
    </table>



</template>

<script setup>
    import {ref, onUnmounted} from 'vue';
    import { Overlay } from 'o365.vue.components.jsx';
    import EditorItem from 'o365.vue.components.InputEditors.Item.vue';
    import vPopover from 'o365.vue.directive.popover.js'
    import InputEditorsList from 'o365.modules.inputEditorsList.ts';
    const emit = defineEmits(['change']);

    const props = defineProps(["dataObject","valuesDataObject"]);
    const list = ref(new InputEditorsList());

    list.value.changeEvt = function(pCol,pType,pVal){
        emit("change",pCol,pType,pVal);
    };
    list.value.updateItems(props.dataObject.data);

    const vCancelToken1 = props.dataObject.on('DataLoaded',(pData)=>{
        list.value.updateItems(pData);
    })
    const vCancelToken2 = props.valuesDataObject?props.valuesDataObject.on('DataLoaded',(pData)=>{
        list.value.updateItemValues(pData);
    }):null;



    onUnmounted(()=>{
        vCancelToken1();
        if(vCancelToken2) vCancelToken2();
    })

    
</script>